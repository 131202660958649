/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./select.vue?vue&type=template&id=cafbef30&scoped=true"
import script from "./select.vue?vue&type=script&lang=js"
export * from "./select.vue?vue&type=script&lang=js"
import style0 from "./select.vue?vue&type=style&index=0&id=cafbef30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cafbef30",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/code/src/components/input/Input.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default})
