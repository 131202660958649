import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "fill-height justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('ContentArea', {
    staticClass: "content-area d-flex flex-column align-center justify-center py-10"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-center"
  }, [!_vm.$fromExtension() ? _c('ImagesSendwow', {
    attrs: {
      "height": "86"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "flex gray-dark--text text-body-1"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('selectTeam.desc')) + "\n      ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "select-area d-flex flex-column pt-5 overflow-y-hidden"
  }, [!_vm.$fromExtension() ? _c('Input', {
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('search'),
      "clearable": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }) : _vm._e(), _vm._v(" "), _c(VList, {
    ref: "list",
    staticClass: "overflow-y-auto"
  }, [_vm.loading.get ? [_c(VListItem), _vm._v(" "), _c(VFadeTransition, {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c(VOverlay, {
    attrs: {
      "absolute": "",
      "opacity": "0"
    }
  }, [_c(VProgressCircular, {
    attrs: {
      "color": "primary",
      "indeterminate": ""
    }
  })], 1)], 1)] : _vm.pageItems.length ? [_vm._l(_vm.pageItems, function (item, i) {
    return [item ? _c(VListItem, {
      key: i,
      staticClass: "px-0",
      on: {
        "click": function click($event) {
          return _vm.onClickTeam(item);
        }
      }
    }, [_c(VListItemContent, {
      staticClass: "py-5"
    }, [_c(VListItemTitle, {
      staticClass: "text-h6 mb-2",
      domProps: {
        "textContent": _vm._s(item.name)
      }
    }), _vm._v(" "), _c(VListItemSubtitle, {
      staticClass: "text-body-1"
    }, [_vm._v("\n                  " + _vm._s(item.description || '　') + "\n                ")])], 1), _vm._v(" "), _c(VListItemIcon, {
      staticClass: "my-7"
    }, [_c('div', {
      staticClass: "text-body-1"
    }, [_c(VIcon, [_vm._v("mdi-account-multiple-outline")]), _vm._v("\n                  " + _vm._s(item.team_members_count) + "\n                ")], 1)])], 1) : _c(VListItem, {
      key: i
    }), _vm._v(" "), _c(VDivider, {
      key: "divider-".concat(i),
      class: {
        transparent: !item
      }
    })];
  })] : _c('div', {
    staticClass: "text-body-2 mt-4"
  }, [_vm.search ? [_vm._v("\n            " + _vm._s(_vm.$t('selectTeam.filteredEmpty')) + "\n          ")] : [_vm._v("\n            " + _vm._s(_vm.$t('selectTeam.empty')) + "\n          ")]], 2)], 2), _vm._v(" "), _vm.pageLength > 1 ? _c(VPagination, {
    attrs: {
      "length": _vm.pageLength
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }) : _vm._e()], 1), _vm._v(" "), !_vm.$fromExtension() && _vm.user.is_admin ? _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary mt-6",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.addTeam
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('selectTeam.addTeam')) + "\n    ")]) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };